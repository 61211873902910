import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

window.TestimonialSlider = sliderName => {
	return {
		init() {
			new Swiper(`#${sliderName}`, {

				// Optional parameters
				slidesPerView: 1,
				spaceBetween: 30,
				speed: 700,
				direction: 'horizontal',
				loop: true,

				// Accessibility features
				a11y: true,
			
				// Auto-swipe
				autoplay: {
					delay: 7000,
				},
			
				// Navigation arrows
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});
		},
		val: sliderName,
	};
};
